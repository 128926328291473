body{font-size:16px}
h1, h2, h3, h4, h5, h6{margin-bottom:8px}
p{margin-bottom:16px}
address{margin-bottom:16px}
ol,ul,dl{margin-bottom:16px}
dd{margin-bottom:8px}
blockquote{margin:0 0 16px}
pre{margin-bottom:16px}
figure{margin:0 0 16px}
caption{padding-top:12px;padding-bottom:12px}
label{margin-bottom:8px}
legend{margin-bottom:8px;font-size:24px}
code{padding:3px 6px}
kbd{padding:3px 6px}
hr{margin-top:16px;margin-bottom:16px;border-top:1px solid #000}
h1{font-size:40px}
h2{font-size:32px}
h3{font-size:28px}
h4{font-size:24px}
h5{font-size:20px}
h6{font-size:16px}

.alert{margin-bottom:16px}
.alert-dismissible{padding-right:40px}
.alert-dismissible .close{top: -2px;right: -20px}
.blockquote{padding:8px 16px;margin-bottom:16px;font-size:20px;border-left:4px solid #eceeef}
.blockquote-reverse{padding-right:16px;border-right:4px solid #eceeef}
.breadcrumb{padding:12px 16px;margin-bottom:16px}
.btn{padding:8px 16px;font-size:16px}
.btn.disabled,.btn:disabled{filter:alpha(opacity=65)}
.btn-toolbar{margin-left:-8px}
.card{margin-bottom:12px;border:1px solid #000}
.card-block{padding:20px}
.card-footer{padding:12px 20px;border-top: 1px solid #000}
.card-header{padding:12px 20px}
.card-header-tabs{margin-right:-10px;margin-bottom:-12px;margin-left:-10px;border-bottom:0}
.card-header-pills{margin-right:-10px;margin-left:-10px}
.card-img-overlay{padding:20px}
.card-subtitle{margin-top:-6px}
.card-title{margin-bottom:12px}
.caret{border-top:4px solid}
.carousel-control-prev,.carousel-control-next{filter:alpha(opacity=50)}
.carousel-control-prev:focus, .carousel-control-prev:hover,.carousel-control-next:focus,.carousel-control-next:hover{filter:alpha(opacity=90)}
.carousel-indicators li{background-color:#fff}
.checkbox input[type=checkbox],.checkbox-inline input[type=checkbox],.radio input[type=radio],.radio-inline input[type=radio]{margin-top:4px}
.clearfix:after{content:"";display:table;clear:both}
.close{font-size:24px;filter:alpha(opacity=50)}
.close:focus,.close:hover{filter:alpha(opacity=75)}
.col-form-label{padding-top:8px;padding-bottom:8px;margin-bottom:0}
.col-form-label-lg{padding-top:12px;padding-bottom:12px;font-size:20px}
.col-form-label-sm{padding-top:4px;padding-bottom:4px;font-size:14px}
.col-form-legend{padding-top:8px;padding-bottom:8px;font-size:16px}
.container{padding-right:15px;padding-left:15px}
.custom-control{padding-left:24px}
.custom-control-indicator{top:4px;width:16px;height:16px}
.custom-control-input{filter:alpha(opacity=0)}
.custom-file{height:40px}
.custom-file-control{height:40px;padding:8px 16px}
.custom-file-input{min-width:224px}
.custom-select{padding:6px 28px 6px 12px;border:1px solid #000;background-image:none \9;padding-right: 0.75rem \9;}
.custom-select-sm{padding-top:6px;padding-bottom:6px}
.display-1{font-size:96px}
.display-2{font-size:88px}
.display-3{font-size:72px}
.display-4{font-size:56px}
.dl-horizontal{margin-right:-30px;margin-left:-30px}
.dropdown-divider{margin:8px 0}
.dropdown-header{font-size:14px;padding: 8px 24px}
.dropdown-item{padding: 3px 24px}
.dropdown-menu{font-size:16px;min-width:160px;padding:8px 0;margin:2px 0 0;border:1px solid #000}
.dropdown-toggle:after{margin-right:4px;margin-left:4px}
.dropup .caret,.navbar-fixed-bottom .dropdown .caret{border-bottom:4px solid}
.fade.show{filter:alpha(opacity=100)}
.fade{filter:alpha(opacity=0)}
.figure-img{margin-bottom:8px}
.form-check{margin-bottom:12px}
.form-check-inline{padding-left:20px}
.form-check-input{margin-top:4px;margin-left:-20px}
.form-check-label{padding-left:20px}
.form-control{padding:8px 12px;font-size:16px;border:1px solid #000}
.form-control-feedback{margin-top:4px}
.form-control-static{padding-top:8px;padding-bottom:8px}
.form-group{margin-bottom:16px}
.form-text{margin-top:4px}
.img-thumbnail{padding:4px}
.input-group-addon{padding:6px 12px;font-size:16px;border:1px solid #000}
.jumbotron{padding:32px 16px;margin-bottom:32px}
.lead{font-size:20px}
.list-group-item{padding:12px 20px;border: 1px solid #000}
.modal-backdrop.fade{filter:alpha(opacity=0)}
.modal-backdrop.show{filter:alpha(opacity=50)}
.m-1{margin: 16px 16px !important}
.mt-1{margin-top: 16px !important}
.mr-1{margin-right: 16px !important}
.mb-1{margin-bottom: 16px !important}
.ml-1{margin-left: 16px !important}
.mx-1{margin-right: 16px !important;margin-left: 16px !important}
.my-1{margin-top: 16px !important;margin-bottom: 16px !important}
.m-2{margin: 24px 24px !important}
.mt-2{margin-top: 24px !important}
.mr-2{margin-right: 24px !important}
.mb-2{margin-bottom: 24px !important}
.ml-2{margin-left: 24px !important}
.mx-2{margin-right: 24px !important;margin-left: 24px !important}
.my-2{margin-top: 24px !important;margin-bottom: 24px !important}
.m-3{margin: 48px 48px !important}
.mt-3{margin-top: 48px !important}
.mr-3{margin-right: 48px !important}
.mb-3{margin-bottom: 48px !important}
.ml-3{margin-left: 48px !important}
.mx-3{margin-right: 48px !important;margin-left: 48px !important}
.my-3{margin-top: 48px !important;margin-bottom: 48px !important}
.navbar{padding:8px 16px}
.navbar-brand{padding-top:4px;padding-bottom:4px;margin-right:16px;font-size:20px}
.navbar-dark .navbar-divider{background-color:#fff}
.navbar-dark .navbar-nav .nav-link,.navbar-dark .navbar-nav .nav-link:focus{color:#fff}
.navbar-dark .navbar-nav .nav-link:hover{color:#fff}
.navbar-divider{padding-top:6.8px;padding-bottom:6.8px;margin-right:16px;margin-left:16px}
.navbar-nav .nav-link{padding-top:6.8px;padding-bottom:6.8px}
.navbar-toggler{padding:8px 12px;font-size:20px}
.navbar-toggleable-xl .navbar-nav .nav-link{padding-right:8px;padding-left:8px}
.p-a-1{padding:16px 16px !important}
.p-a-2{padding:24px 24px !important}
.p-a-3{padding:48px 48px !important}
.p-b-1{padding-bottom:16px !important}
.p-b-2{padding-bottom:24px !important}
.p-b-3{padding-bottom:48px !important}
.p-l-1{padding-left:16px !important}
.p-l-2{padding-left:24px !important}
.p-l-3{padding-left:48px !important}
.p-r-1{padding-right:16px !important}
.p-r-2{padding-right:24px !important}
.p-r-3{padding-right:48px !important}
.p-t-1{padding-top:16px !important}
.p-t-2{padding-top:24px !important}
.p-t-3{padding-top:48px !important}
.p-x-1{padding-right:16px !important;padding-left:16px !important}
.p-x-2{padding-right:24px !important;padding-left:24px !important}
.p-x-3{padding-right:48px !important;padding-left:48px !important}
.p-y-1{padding-top:16px !important;padding-bottom:16px !important}
.p-y-2{padding-top:24px !important;padding-bottom:24px !important}
.p-y-3{padding-top:48px !important;padding-bottom:48px !important}
.page-link{padding:8px 12px}
.pagination{margin-top:16px;margin-bottom:16px}
.pagination-lg .page-link{padding:12px 24px;font-size:20px}
.pagination-sm .page-link{padding:4.4px 12px;font-size:14px}
.popover{font-size:14px;border:1px solid #000}
.popover-title{font-size:16px}
.progress{height:16px;margin-bottom:16px}
.rounded-top{border-top-right-radius:4px;border-top-left-radius:4px}
.rounded-right{border-bottom-right-radius:4px;border-top-right-radius:4px}
.rounded-bottom{border-bottom-right-radius:4px;border-bottom-left-radius:4px}
.rounded-left{border-bottom-left-radius:4px;border-top-left-radius:4px}
.table th,.table td{padding:12px}
.table{margin-bottom:16px}
.table-sm th,.table-sm td{padding:5px}
.table-responsive{min-height:0%}
.tooltip{font-size:14px}
.tooltip.show{filter:alpha(opacity=90)}
